import axios from '../plugins/axios';
import store from './index';

export default {
    namespaced: true,
    state: () => ({
        provinces: [],
        districts: [],
        wards: [],
    }),
    mutations: {
        setProvinces(state, v) {
            state.provinces = v;
        },
        setDistricts(state, v) {
            state.districts = v;
        },
        setWards(state, v) {
            state.wards = v;
        },
        reset(state) {
            state.districts = [];
            state.wards = [];
        }
    },
    actions: {
        async fetchProvinces({state}) {
            let { data } = await axios.get("/api/zones?provinces");
            state.provinces = data;
            return data;
        },
        async fetchDistricts({state},provinceId) {
            let { data } = await axios.get("/api/zones?districts&parent_id=" + provinceId);
            state.districts = data;
            return data;
        },
        async fetchWards({state},districtId) {
            let { data } = await axios.get("/api/zones?wards&parent_id=" + districtId);
            state.wards = data;
            return data;
        },
        loadProvinces({ state }) {
            return new Promise((resolve, reject) => {
                state.provinces = [];

                const db = store.state.db;

                const openRequest = db
                    .transaction("provinces")
                    .objectStore("provinces").getAll();

                openRequest.onsuccess = (event) => {
                    let provinces = event.target.result;

                    state.provinces = provinces;
                    resolve(provinces);

                };

                openRequest.onerror = (err) => {
                    reject(err)
                }
            });
        },
        loadDistricts({ state }, provinceId) {
            return new Promise((resolve, reject) => {
                state.districts = [];

                const db = store.state.db;

                const openRequest = db
                    .transaction("districts")
                    .objectStore("districts")
                    .index('parentIdx')
                    .getAll(IDBKeyRange.only(provinceId));

                openRequest.onsuccess = (event) => {
                    const districts = event.target.result;
                    state.districts = districts;
                    resolve(districts);
                };

                openRequest.onerror = (error) => {
                    reject(error);
                }
            });
        },
        loadWards({ state }, districtId) {
            return new Promise((resolve, reject) => {
                state.wards = [];

                const db = store.state.db;

                const openRequest = db
                    .transaction("wards")
                    .objectStore("wards")
                    .index('parentIdx')
                    .getAll(IDBKeyRange.only(districtId));

                openRequest.onsuccess = (event) => {
                    const wards = event.target.result;
                    state.wards = wards;
                    resolve(wards);

                };

                openRequest.onerror = (error) => {
                    reject(error);
                }

            });


        }
    },
    getters: {

    }
}