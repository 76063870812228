import axios from '../plugins/axios';
import { buildQuery } from '../utils';

export default {
    namespaced: true,

    state: () => ({
        loading: false,
        syncing: false,
        viewMode: "onsale",
        viewModes: [
            { text: "Đang bán", value: "onsale" },
            { text: "Công khai", value: "public" },
            { text: "Nhóm của bạn", value: "groups" },
            { text: "Riêng tư", value: "private" },
            { text: "Đã bán", value: "sold" },
        ],
        items: [],
        total: 0,
        currentPage: 1,
        itemsPerPage: 20,
        selectedItem: null,
        detailDialog: {
            isOpen: false,
            mode: "CREATE",
        },
        zoneDialog: false,
        zones: {},
        filterDialog: {
            isOpen: false,
            target: "",
        },
        filterData: {
            facade: [0, 0],
            type: null,
            area: [0, 0],
            price: [0, 0],
            road: null,
            residential: [0, 0],
            area_unit: "m",
            price_unit: "m",
            dt_distance: 0,
            center_distance: 0,
            distance_unit: "km",
        },
        mapDialog: {
            open: false,
            value: "",
        },
        bottomSheet: {
            isOpen: false
        }
    }),

    mutations: {
        setViewMode(state, v) {
            state.viewMode = v;
        },
        setFilterData(state, v) {
            state.filterData = v;
        },
        setZones(state, v) {
            state.zones = v;
        }
    },
    actions: {
        async fetch({ state }) {
            if (this.loading) return;

            try {
                state.loading = true;

                const url = `/api/sources?${state.viewMode}&${buildQuery(state.zones, state.filterData)}&page=${state.currentPage}`;

                const { data: resBody } = await axios.get(url);

                state.total = resBody.total;
                state.noMore = resBody.to >= resBody.total;

                state.loading = false;

                if (state.currentPage == 1) {
                    state.items = resBody.data;
                } else {
                    state.items.push(...resBody.data);
                }
            } finally {
                state.loading = false;
            }
        },
        fetchMore({ state, dispatch }) {
            if (this.loading) return;
            if (state.noMore) return;

            state.currentPage += 1;

            dispatch('fetch');
        },

        async updateState({ state }, { item, status }) {
            if (this.loading) return;
            state.loading = true;

            await axios.post(`/api/sources/update-state/${item.id}`, {
                state: status,
            }).finally(() => {
                state.loading = false;
            });

            item.state = status;
        },

        async deleteOne({ state }, item) {
            state.loading = true;

            await axios.delete(`/api/sources/${item.id}`).finally(() => {
                state.loading = false;
            });

            state.items = state.items.filter((el) => el.id !== item.id);
        },

        async create({ state }, item) {
            if (this.loading) return;
            state.loading = true;

            let { data } = await axios.post("/api/sources", item).finally(() => {
                state.loading = false;
            });

            state.items.unshift(data);
        },

        async update({ state }, item) {
            if (this.loading) return;

            state.loading = true;

            let { data } = await axios.put(`/api/sources/${item.id}`, item).finally(() => {
                state.loading = false;
            });

            let idx = state.items.findIndex(el => el.id == item.id);

            if ((state.viewMode == 'onsale' && data.state != 'sold') || state.viewMode == data.state) {
                state.items[idx] = data;
            } else {
                state.items.splice(idx, 1);
            }

            return data;
        },

        openFilter({ state }, target) {
            state.filterDialog = {
                isOpen: true,
                target,
            };
        },

        refresh({ state, dispatch }) {
            if (this.loading) return;

            state.items = [];
            state.total = 0;
            state.currentPage = 1;
            dispatch('fetch');
        },
        openAddDialog({ state }) {
            state.detailDialog = {
                isOpen: true,
                mode: "CREATE",
            };
        },
        openEditDialog({ state }, item) {
            state.selectedItem = item;
            state.detailDialog = {
                isOpen: true,
                mode: "EDIT",
            };
        },
        openMapDialog({ state }, item) {
            state.mapDialog = {
                open: true,
                value: item.location,
            };
        },
        openBottomMenu({ state }, item) {
            state.selectedItem = item;

            state.bottomSheet = {
                isOpen: true
            }
        }
    },
    getters: {

    }
};