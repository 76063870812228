import TimeAgo from 'javascript-time-ago'
import vi from 'javascript-time-ago/locale/vi'

TimeAgo.addDefaultLocale(vi)

const timeAgo = new TimeAgo('vi-VN')

const filter = (v) => {
let date = new Date(v);

  return timeAgo.format(date)
}

export default filter