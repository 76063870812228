const options = {
  namespaced: true,
  state: () => ({
    types: [
      { value: "", text: "Tất cả" },
      { value: "dat-trang", text: "Đất trắng" },
      { value: "dat-nen", text: "Đất nền" },
      { value: "dat-sao", text: "Đất sào" },
      { value: "vuon-cao-su", text: "Vườn cao su" },
      { value: "vuon-tieu", text: "Vườn tiêu" },
      { value: "vuon-dieu", text: "Vườn điều" },
      { value: "vuon-cafe", text: "Vườn cà phê" },
      { value: "vuon-sau-rieng", text: "Vườn sầu riêng" },
      { value: "vuon-trai-cay", text: "Vườn trái cây" },
      { value: "nha-vuon", text: "Nhà vườn" },
      { value: "nha-pho", text: "Nhà phố" },
      { value: "view-song", text: "View sông" },
      { value: "view-ho", text: "View hồ" },
    ],
    roads: [
      { value: "", text: "Tất cả" },
      { value: "nhua", text: "Nhựa" },
      { value: "be-tong", text: "Bê tông" },
      { value: "dat", text: "Đất" },
      { value: "ql", text: "Quốc lộ" },
      { value: "dt", text: "ĐT" },
    ],
    legals: [
      { value: "", text: "Tất cả" },
      { value: "so-hong", text: "Sổ hồng" },
      { value: "so-do", text: "Sổ đỏ" },
      { value: "trich-luc", text: "Trích lục" },
    ],
    prices: [
      { value: "", text: "Tất cả" },
      { value: "300tr", text: "Dưới 300 triệu" },
      { value: "400tr", text: "Dưới 500 triệu" },
      { value: "300tr", text: "Dưới 700 triệu" },
      { value: "1T", text: "Dưới 1 tỷ" },
      { value: "3T", text: "Dưới 3 tỷ" },
      { value: "5T", text: "Dưới 5 tỷ" },
      { value: "7T", text: "Dưới 7 tỷ" },
      { value: "10T", text: "Dưới 10 tỷ" },
      { value: "20T", text: "Dưới 20 tỷ" },
      { value: "50T", text: "Dưới 50 tỷ" },
      { value: "50T", text: "Dưới 100 tỷ" },
    ],
    priceTypes: [
      { value: "sum", text: "Tổng" },
      { value: "per_meter", text: "Mét ngang" },
      { value: "per_square_meter ", text: "Mét vuông" },
    ],
    states: [
      { value: "public", text: "Công khai" },
      { value: "groups", text: "Nhóm của bạn" },
      { value: "private", text: "Riêng tư" },
      { value: "sold", text: "Đã bán" },
    ],
  }),
  mutations: {},
  actions: {},
  getters: {
    noEmptyOptions: (state) => {
      const out = {};

      for (const key of ["types", "roads", "legals"]) {
        const options = [...state[key]];

        options.splice(0, 1);

        out[key] = options;
      }

      out["priceTypes"] = [...state["priceTypes"]];
      out["states"] = [...state["states"]];

      return out;
    },
    roadsMap(state) {
      const hashmap = {};

      for (const el of state.roads) {
        if (!el.value) continue;
        hashmap[el.value] = el.text;
      }

      return hashmap;
    },

    typesMap(state) {
      const hashmap = {};

      for (const el of state.types) {
        if (!el.value) continue;
        hashmap[el.value] = el.text;
      }

      return hashmap;
    },

    noSumPriceTypes(state) {
      let data = [...state.priceTypes];
      data.shift();
      return data;
    },
  },
};

module.exports = options;
