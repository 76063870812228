import axios from "axios";
import Vue from "vue";
import store from "../store";

const { VUE_APP_RESOURCE_URL } = process.env;

axios.defaults.baseURL = VUE_APP_RESOURCE_URL;
axios.defaults.headers = {
  accept: "application/json",
};

axios.interceptors.request.use(
  function (config) {
    const { token } = store.state.auth;

    if (token) {
      config.headers = {
        ...axios.defaults.headers,
        Authorization: `Bearer ${token}`,
      };
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response) {
      if (error.response.status == 401) {
        store.dispatch("showInfo", "Phiên đăng nhập đã kết thúc!");
        store.dispatch('auth/signOut');
      } else {
        store.dispatch("showError", error.response.data.message);
      }

    } else {
      store.dispatch("showError", "Không có kết nối internet!");
    }
    return Promise.reject(error);
  }
);

Vue.prototype.$http = axios;

export default axios;
