<template>
  <v-dialog v-model="isOpen" fullscreen persistent>
    <v-card light>
      <v-btn icon class="close-btn" @click="onCloseClick">
        <v-icon color="error">fa fa-times</v-icon>
      </v-btn>
      <div class="main" id="ZMP_container">
        <div class="brand">Nguồn Đất App</div>
        <div class="subject" txt="add_to_home_screen">
          Hướng dẫn thêm ứng dụng vào điện thoại
        </div>
        <div class="preview">
          <div class="content">
            <img class="bg" src="@/assets/img/nguondat-mockup.jpg" />
          </div>
        </div>
        <div class="guide">
          <div class="content">
            <div class="tips">
              <p class="text" txt="tap_share_icon">
                1. Truy cập
                <a href="https://app.nguondat.net" target="_blank"
                  ><strong>app.nguondat.net</strong></a
                >
                bằng Safari
              </p>
            </div>
            <div class="tips">
              <p class="text" txt="tap_share_icon">
                2. Chọn <strong>Biểu tượng Chia sẻ</strong> ở thanh bên dưới
              </p>
              <svg
                class="icon"
                width="16"
                height="20"
                viewBox="0 0 16 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.99549 13.0616C8.3831 13.0616 8.71662 12.7426 8.71662 12.3704V3.26983L8.66254 1.94063L9.26648 2.56978L10.6366 4.00532C10.7628 4.1471 10.9431 4.21799 11.1234 4.21799C11.493 4.21799 11.7814 3.95215 11.7814 3.58883C11.7814 3.40275 11.7003 3.26097 11.5651 3.12805L8.51831 0.239256C8.33803 0.0620292 8.18479 0 7.99549 0C7.81521 0 7.66197 0.0620292 7.47268 0.239256L4.42592 3.12805C4.2907 3.26097 4.21859 3.40275 4.21859 3.58883C4.21859 3.95215 4.48901 4.21799 4.86761 4.21799C5.03887 4.21799 5.23718 4.1471 5.36338 4.00532L6.72451 2.56978L7.33746 1.94063L7.28338 3.26983V12.3704C7.28338 12.7426 7.60789 13.0616 7.99549 13.0616ZM2.83042 20H13.1696C15.0535 20 16 19.0784 16 17.253V8.40939C16 6.58396 15.0535 5.66238 13.1696 5.66238H10.6546V7.08906H13.1425C14.0349 7.08906 14.5487 7.56757 14.5487 8.48915V17.1732C14.5487 18.0948 14.0349 18.5733 13.1425 18.5733H2.84845C1.94704 18.5733 1.45127 18.0948 1.45127 17.1732V8.48915C1.45127 7.56757 1.94704 7.08906 2.84845 7.08906H5.34535V5.66238H2.83042C0.946479 5.66238 0 6.58396 0 8.40939V17.253C0 19.0784 0.946479 20 2.83042 20Z"
                  fill="#007AFF"
                />
              </svg>
            </div>
            <div class="tips">
              <p class="text" txt="tap_add_to_home_screen">
                3. Chọn mục <strong>Thêm vào MH chính</strong>
              </p>
              <svg
                class="icon"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.7857 0H3.21427C1.43995 0.00210937 0.00210937 1.43995 0 3.21427V14.7857C0.00210937 16.56 1.43995 17.9979 3.21427 18H14.7857C16.56 17.9979 17.9979 16.56 18 14.7857V3.21427C17.9979 1.43995 16.56 0.00210937 14.7857 0ZM16.7143 14.7857C16.7143 15.8508 15.8508 16.7143 14.7857 16.7143H3.21427C2.14915 16.7143 1.2857 15.8508 1.2857 14.7857V3.21427C1.2857 2.14915 2.14915 1.2857 3.21427 1.2857H14.7857C15.8508 1.2857 16.7143 2.14915 16.7143 3.21427V14.7857Z"
                  fill="#001A33"
                />
                <path
                  d="M12.2143 8.35714H9.64282V5.78571C9.64282 5.43065 9.35501 5.14284 8.99995 5.14284C8.6449 5.14284 8.35709 5.43065 8.35709 5.78571V8.35714H5.78569C5.43064 8.35714 5.14282 8.64496 5.14282 9.00001C5.14282 9.35506 5.43064 9.64284 5.78569 9.64284H8.35712V12.2143C8.35712 12.5693 8.64494 12.8571 8.99999 12.8571C9.35504 12.8571 9.64282 12.5693 9.64282 12.2143V9.64284H12.2143C12.5693 9.64284 12.8571 9.35502 12.8571 8.99997C12.8571 8.64492 12.5693 8.35714 12.2143 8.35714Z"
                  fill="#001A33"
                />
              </svg>
            </div>
          </div>
          <svg
            class="arrow"
            width="10"
            height="24"
            viewBox="0 0 10 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5 24L9.33013 16.5L0.669873 16.5L5 24ZM4.25 3.27835e-08L4.25 17.25L5.75 17.25L5.75 -3.27835e-08L4.25 3.27835e-08Z"
              fill="#001A33"
            />
          </svg>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    isOpen: false,
  }),
  created() {
    if (this.isIos && this.isSafari) {
      this.isOpen = localStorage.getItem("show_guide") ? false : true;
    }
  },
  methods: {
    onCloseClick() {
      this.isOpen = false;
      localStorage.setItem("show_guide", false);
    },
    isIos() {
      return (
        [
          "iPad Simulator",
          "iPhone Simulator",
          "iPod Simulator",
          "iPad",
          "iPhone",
          "iPod",
        ].includes(navigator.platform) ||
        (navigator.userAgent.includes("Mac") && "ontouchend" in document)
      );
    },

    isSafari() {
      const ua = navigator.userAgent.toLowerCase();
      if (ua.indexOf("safari") != -1) {
        if (ua.indexOf("chrome") > -1 || navigator.userAgent.match("CriOS")) {
          return false;
        } else {
          return true;
        }
      }
      return false;
    },
  },
};
</script>

<style scope>
@font-face {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 400;
  src: local("SF Pro Display"),
    url("https://h5.zdn.vn/static/fonts/SFPRODISPLAYREGULAR.woff")
      format("woff");
}

@font-face {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 500;
  src: local("SF Pro Display"),
    url("https://h5.zdn.vn/static/fonts/SFPRODISPLAYMEDIUM.woff") format("woff");
}

@font-face {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 600;
  src: local("SF Pro Display"),
    url("https://h5.zdn.vn/static/fonts/SFPRODISPLAYBOLD.woff") format("woff");
}

.main {
  font-family: "SF Pro Display";
}

.backguide {
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.backguide .footer {
  width: 64%;
  height: 3rem;
  padding-top: 2.5rem;
}

.backguide .enter {
  height: 3rem;
  width: calc(100% - 32px);
  background: #0068ff;
  border: 0;
  color: white;
  border-radius: 8px;
  font-size: 1rem;
}

.backguide .zmp-brand {
  position: absolute;
  bottom: 1rem;
  left: 0rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding-bottom: env(safe-area-inset-bottom); */
  color: #667685;
  font-weight: 500;
}

.backguide .zmp-brand p {
  margin-left: 0.33rem;
}

.backguide .tips {
  margin-top: 1.29rem;
  line-height: 0.2rem;
}

.backguide .icon {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
}

.backguide .appname {
  margin-top: 1rem;
  color: #27272a;
  font-weight: 500;
}

.main {
  text-align: center;
  padding: 1rem;
}

.brand {
  margin-top: 0.5rem;
  color: #99a3ad;
}

.subject {
  margin-top: 0.5rem;
  font-size: 1.75rem;
  font-weight: 600;
}

.preview {
  margin-top: 2rem;
  position: relative;
}

.preview .content {
  position: relative;
  margin: 0 1rem;
}

.preview .bg {
  width: 100%;
  position: relative;
  max-width: 500px;
}

.preview .icon {
  position: absolute;
  display: block;
  border-radius: 21%;
}

.preview .title {
  position: absolute;
  text-align: center;
  height: 3rem;
  font-weight: 600;
}

.preview .title p {
  overflow: hidden;
  text-overflow: ellipsis;
}

.preview .icon.large {
  width: 4.6rem;
  height: 4.6rem;
  left: 13.5rem;
  top: 1.6rem;
}

.preview .title.large {
  width: 8rem;
  left: 11.8rem;
  top: 6.5rem;
  font-size: 1rem;
}

.preview .icon.small {
  width: 2.75rem;
  height: 2.75rem;
  left: 14.4rem;
  top: 14rem;
}

.preview .title.small {
  width: 0.16rem;
  left: 0.78rem;
  top: 1.266rem;
}

@media only screen and (min-width: 600px) {
  .preview .icon.large {
    left: calc(50% + 70px);
  }

  .preview .title.large {
    left: calc(50% + 30px);
  }

  .preview .icon.small {
    left: calc(50% + 94px);
  }
}

.guide {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 3rem;
  max-width: 800px;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.guide .content {
  position: relative;
  z-index: 20;
  padding: 0 0.5rem;
  margin: 0 auto;
  border-radius: 0.5rem;
  background: #f7f7f7;
  margin: 0 1rem;
}

.guide .tips {
  position: relative;
  z-index: 20;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem 0;
}

.guide .tips:first-child {
  border-bottom: 1px solid #e4e8ec;
}

.guide .text {
  width: 100%;
  font-size: 15px;
}

.guide .icon {
  margin: auto;
}

.guide .arrow {
  width: auto;
  height: 1.5rem;
  position: absolute;
  left: 50%;
  bottom: -2rem;
  transform: translateX(-50%);
  z-index: 10;
}

.ios-guide {
  width: 100vw;
  height: 100vh;
}

.ios-guide .center {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
}

.ios-guide .text {
  font-size: 1rem;
  color: #001a33;
  font-family: "SF Pro Display";
  font-weight: normal;
  text-align: center;
}

.ios-guide .safari-logo {
  width: 3.5rem;
  height: 3.5rem;
  display: block;
  object-fit: contain;
  margin-bottom: 1.5rem;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
}
</style>
