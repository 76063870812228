import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "main",
    component: () => import("../screens/MainScreen.vue"),
    meta: { auth: true }
  },
  {
    path: "/signin",
    name: "signin",
    component: () => import("../screens/SignInScreen.vue"),
    meta: { auth: false }
  },
  {
    path: "/signup",
    name: "signup",
    component: () => import("../screens/SignUpScreen.vue"),
    meta: { auth: false }
  },
  {
    path: "/account-setup",
    name: "account-setup",
    component: () => import("../screens/AccountSetupScreen.vue"),
    meta: { auth: true }
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes,
});

router.beforeEach(async (to, from, next) => {
  const { auth: requiresAuth = true } = to.meta

  if (!requiresAuth) {
    return next();
  }

  const token = localStorage.getItem("access_token");

  if (token) {
    if (['signin', 'signup'].includes(to.name)) {
      return next("/");
    }

    let user = store.state.auth.user;

    if (!user) {
      try {
        user = await store.dispatch('auth/refreshUser');
      } catch (error) {
        return next("/signin");
      }
    }

    // if (user && to.name != 'account-setup' && (!user.default_zone || !user.name)) {
    //   return next("/account-setup");
    // }

    return next();

  }

  if (['signin', 'signup'].includes(to.name)) {
    return next();
  }

  return next("/signin");

});

export default router;
