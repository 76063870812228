const filter = (v, type) => {
  const date = new Date(v)
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')
  const hour = String(date.getHours()).padStart(2, '0')
  const min = String(date.getMinutes()).padStart(2, '0')
  const sec = String(date.getSeconds()).padStart(2, '0')

  if (type === 'date') {
    return `${day}-${month}-${year}`
  } else if (type === 'time') {
    return `${hour}:${min}:${sec}`
  }

  return `${day}-${month}-${year} ${hour}:${min}:${sec}`
}

export default filter