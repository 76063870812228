<template>
  <div v-if="pack">
    <div class="mb-3">
      * Chú ý: Khách hàng chuyển khoản vào 1 trong những tài khoản ngân hàng sau
      đây và bấm xác nhận và chờ một vài phút để hệ thống cập nhật. Xin cảm ơn!
    </div>

    <v-card
      v-for="(bank, i) of banks"
      :key="`bank-card-${bank.name}`"
      rounded="lg"
      flat
      :color="$vuetify.theme.isDark ? 'grey darken-3' : 'grey lighten-3'"
      class="mb-3"
    >
      <div class="px-4 py-3">
        <div class="d-flex flex-no-wrap justify-space-between align-center">
          <div>
            <div class="">{{ bank.name }}</div>
            <div
              class="blue--text"
              @click="copy(bank.accountNumber.replaceAll(' ', ''))"
            >
              <span class="text-h6">{{ bank.accountNumber }}</span>
            </div>
            <div class="font-weight-bold">{{ bank.ownerName }}</div>
            <div
              class="caption mt-2"
              @click="copy(`${pack.code} UID${user.id}`)"
            >
              <span class="">NỘI DUNG: </span>
              <span class="font-weight-bold blue--text"
                >{{ pack.code }} UID{{ user.id }}</span
              >
            </div>
            <div class="caption">
              <span>SỐ TIỀN: </span>
              <span class="font-weight-bold"
                >{{ getFinalAmount(pack).toLocaleString("vi-VN") }}đ</span
              >
            </div>
          </div>
          <v-avatar rounded="lg" size="120" @click="selected = i">
            <img
              :style="{ filter: selected == i ? 'none' : 'blur(1px)' }"
              :src="`https://img.vietqr.io/image/${bank.name}-${
                bank.accountNumber
              }-compact.png?amount=${getFinalAmount(pack)}&addInfo=${
                pack.code
              }&accountName=${bank.ownerName}`"
              contain
            />
            <v-overlay :value="selected != i" absolute opacity="0.6">
              <span class="font-weight-bold" style="text-shadow: 0 0 2px #000"
                >Nhấn để xem mã QR</span
              >
            </v-overlay>
          </v-avatar>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  components: {},
  name: "BankingCards",

  props: {
    pack: null,
  },

  data: () => ({
    banks: [
      {
        name: "ACB",
        accountNumber: "229 268 268",
        ownerName: "VU VAN NINH",
      },
      {
        name: "VPBANK",
        accountNumber: "183 253 247",
        ownerName: "VU VAN NINH",
      },
    ],
    selected: 0,
  }),

  computed: {
    ...mapState("auth", ["user"]),
  },

  methods: {
    ...mapActions(["showInfo"]),
    getFinalAmount(pack) {
      return pack.amount - (pack.amount * pack.discount_percent) / 100;
    },
    copy(v) {
      navigator.clipboard.writeText(v);
      this.showInfo("Đã sao chép");
    },
  },
};
</script>

<style></style>
