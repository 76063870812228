const filter = (v, size) => {
  if (!v) return "@/assets/img/placeholder-light.jpg";

  let baseUrl;
  let imgName;

  if (v.startsWith("http")) {
    const urlParts = v.split("|").shift().split("%2F");
    baseUrl = urlParts.shift();

    imgName = urlParts.pop();
  } else {
    baseUrl =
      "https://firebasestorage.googleapis.com/v0/b/nguon-dat.appspot.com/o/";
    imgName = v.split("%2F").pop();
  }

  if (size) {
    return `${baseUrl}%2F${size}%2F${imgName}`;
  }

  return `${baseUrl}%2F${imgName}`;
};

export default filter;
