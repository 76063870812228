import axios from '../plugins/axios';

export default {
    namespaced: true,
    state: () => ({
        items: []
    }),
    mutations: {

    },
    actions: {
        async fetch({state}, bounds) {
            let ne = bounds.getNorthEast();
            let sw = bounds.getSouthWest();

             let {data} = await axios.get(`/api/sources?ne[]=${ne.lat()}&ne[]=${ne.lng()}&sw[]=${sw.lat()}&sw[]=${sw.lng()}`);

             state.items = data;

             return data;
        }
    },
    getters: {

    }
}