import Vue from 'vue'

// Auto import all filters
const moduleFiles = require.context('./', true, /filter.js$/, 'sync')

moduleFiles.keys().forEach((filename) => {
  const { default: filter } = moduleFiles(filename)

  const filterName = filename.replace('./', '').replace('.filter.js', '')

  Vue.filter(filterName, filter)

})

