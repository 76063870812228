import Vue from 'vue'
import * as VueGoogleMaps from 'vue2-google-maps'

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBXoHGnFb3-Z1ttXzr34vTmepNad1qAftw',
    region: 'VI',
    language: 'vi',
  },
  installComponents: true
})
