import _ from 'lodash';
import store from '../store';

export function toPricePerMeter({ final_price, facade }) {
  return Math.round(final_price / facade / 100000) * 100000;
}

export function copyContentToClipboard(context, item) {
  const roadsMap = store.getters['options/roadsMap']
  const filters = context.$options.filters;

  let text = "";

  text += `☞ Diện tích: ${filters.area(item.area)}`;

  text += `\n☞ Thổ cư: ${item.residential ? filters.area(item.residential) : "Không"
    }`;

  if (item.facade) {
    text += `\n☞ Mặt tiền: ${item.facade}m (Đường ${roadsMap[
      item.road
    ].toLowerCase()})`;
  }

  text += `\n☞ Cách ĐT ${item.dt_distance}km`;


  text += `\n☞ Giá: ${filters.price(item.final_price)} | ${filters.price(
    toPricePerMeter(item)
  )}/m`;


  text += `\n☞ Địa chỉ: ${item.address}`;

  if (item.description) {
    text += `\n☞ ${item.description}`;
  }

  navigator.clipboard.writeText(text);
}

export async function copyLinkToClipboard(item, includeMap = false) {
  const { VUE_APP_RESOURCE_URL } = process.env;

  let link = `${VUE_APP_RESOURCE_URL}/chia-se/${item.id}`;

  if (includeMap) {
    link += "?key=" + item.slug;
  }

  navigator.clipboard.writeText(link);
}

export function copyMapLinkToClipboard(item) {
  const link = `https://google.com/maps/place/${item.location}/@${item.location},15z`;
  navigator.clipboard.writeText(link);
}

export function buildQuery(zones, filterData) {
  const filter = {
    province: zones[1] ? zones[1].id : null,
    district: zones[2] ? zones[2].id : null,
    ward: zones[3] ? zones[3].id : null,
    type: filterData.type ? filterData.type.value : null,
    road: filterData.road ? filterData.road.value : null,
    facade: filterData.facade,
    residential: filterData.residential,
    area: filterData.area.map((item) =>
      filterData.area_unit === "m"
        ? item
        : filterData.area_unit === "s"
          ? item * 1000
          : item * 10000
    ),
    price: filterData.price.map((item) =>
      filterData.price_unit === "m"
        ? item * 1000000
        : item * 1000000000
    ),
    dt_distance: filterData.dt_distance,
    center_distance: filterData.center_distance,
  };

  const conditions = [];
  for (const key in filter) {
    const value = filter[key];

    if (_.isArray(value)) {
      if (value[0] && value[1]) {
        conditions.push(`${key}=${value[0]}-${value[1]}`);
      } else if (value[0]) {
        conditions.push(`${key}=${value[0]}-max`);
      } else if (value[1]) {
        conditions.push(`${key}=0-${value[1]}`);
      }
    } else if (_.isObject(value) && value.value) {
      conditions.push(`${key}=${value.value}`);
    } else if (value) {
      conditions.push(`${key}=${value}`);
    }
  }

  return conditions.join("&");
}

export default {
  copyContentToClipboard,
  toPricePerMeter,
  copyLinkToClipboard,
  copyMapLinkToClipboard
};
