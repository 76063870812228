const RESOURCE_URL = process.env.VUE_APP_RESOURCE_URL

const filter = (value, sub) => {
  if (!value) {
    return ''
  }

  if (sub) {
    return `${RESOURCE_URL}/${sub}/${value}`
  }

  return `${RESOURCE_URL}/${value}`
}

export default filter
