import axios from "../plugins/axios";
import { state } from "./options";

export default {
    namespaced: true,
    state: () => ({
        token: localStorage.getItem("access_token"),
        user: null,
    }),
    mutations: {
        setAuth(state, { token, user }) {
            state.user = user;
            state.token = token;
        },

        setUser(state, data) {
            state.user = data;
        },
    },
    actions: {
        async requestSignUp({ state }, credentials) {
            const { data } = await axios.post(
                "/api/auth/sign-up",
                credentials
            );

            state.user = data.user;
            state.token = data.token;
            localStorage.setItem("access_token", data.token);

            return data;
        },
        async requestSignIn({ state }, { credentials, remember = true }) {
            const { data } = await axios.post(
                "/api/auth/sign-in",
                credentials
            );

            state.user = data.user;
            state.token = data.token;

            if (remember) {
                localStorage.setItem("access_token", data.token);
                localStorage.setItem("default_zone", data.user.default_zone);
            }

            return { ...data };
        },
        async requestSignOut({ dispatch }) {
            try {
                await axios.get("/api/auth/sign-out");
                await dispatch('signOut');
            }
            catch (error) {
                return error;
            }

            return true;
        },
        async signOut() {
            state.token = '';
            state.user = '';
            localStorage.removeItem("access_token");
            localStorage.removeItem("default_zone");
            return true;
        },
        async updateProfile({ state }, user) {
            let { data } = await axios.put(`/api/users/${state.user.id}`, user);

            state.user = data;
            localStorage.setItem("user", JSON.stringify(data));

            return { ...data };
        },
        async refreshUser({ state }) {
            let { data } = await axios.get('/api/auth/user');

            state.user = data;
            if (data.default_zone) {
                localStorage.setItem("default_zone", data.default_zone);
            }

            return { ...data };
        },
        async updateDefaultZone({ state }, zone) {
            let { data } = await axios.put(`/api/users/${state.user.id}`, {
                default_zone: JSON.stringify(zone),
            });

            state.user = data;
            localStorage.setItem("default_zone", data.default_zone);
        }
    },
    getters: {

    }
}