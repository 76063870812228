<template>
  <v-app>
    <div v-if="error" class="fill-height d-flex justify-center align-center">
      <div class="text-center">
        <h1 class="mb-2">Đã có lỗi xảy ra!</h1>
        <v-btn large color="warning" @click="init">
          <v-icon left>fa fa-rotate</v-icon>
          <span>Khởi động lại</span>
        </v-btn>
      </div>
    </div>

    <div
      v-else-if="loading"
      class="fill-height d-flex justify-center align-center"
    >
      <v-progress-circular color="blue" indeterminate />
    </div>

    <v-main v-else>
      <router-view />
    </v-main>

    <v-snackbar :value="snackbar.isOpen" :color="snackbar.color" bottom app>
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn icon v-bind="attrs" @click="closeSnackbar">
          <v-icon size="20">fal fa-times</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <payment-dialog v-model="paymentDialog" />
    <guide-dialog />
  </v-app>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import GuideDialog from "./components/GuideDialog.vue";
import PaymentDialog from "./views/payment/PaymentDialog.vue";

export default {
  components: { PaymentDialog, GuideDialog },
  name: "App",

  data: () => ({
    loading: false,
    error: null,
  }),

  computed: {
    ...mapState(["snackbar", "confirmDialog", "paymentDialog"]),
  },

  watch: {
    "$vuetify.theme.isDark": {
      handler(v) {
        console.log("Theme Changed");
        this.setDocumentThemeColor(v);
      },
      deep: true,
    },
  },

  created() {
    this.init();
  },

  methods: {
    ...mapMutations(["setOnline", "setViewport"]),
    ...mapActions(["closeSnackbar"]),
    // ...mapActions("auth", ["refreshUser"]),
    // ...mapActions("zones", ["fetchProvinces", "fetchDistricts", "fetchWards"]),
    async init() {
      try {
        this.loading = true;
        this.listenConnection();
        this.registerTheme();
        this.listenViewport();
      } catch (error) {
        this.error = error;
      } finally {
        this.loading = false;
      }
    },
    setDocumentThemeColor(isDark) {
      document
        .querySelector('meta[name="theme-color"]')
        .setAttribute("content", isDark ? "#1E1E1E" : "#FFFFFF");
    },
    listenConnection() {
      const setOnline = this.setOnline;

      window.addEventListener("online", function () {
        setOnline(true);
      });

      window.addEventListener("offline", function () {
        setOnline(false);
      });
    },
    listenViewport() {
      visualViewport.onresize = (e) => {
        let { width, height, offsetLeft, offsetTop, pageLeft, pageTop } =
          e.currentTarget;

        this.setViewport({
          width,
          height,
          offsetLeft,
          offsetTop,
          pageLeft,
          pageTop,
        });
      };

      visualViewport.onscroll = (e) => {
        e.preventDefault();
        window.scrollTo(0, 0);
      };
    },
    registerTheme() {
      const themeSetting = localStorage.getItem("theme") ?? "auto";

      if (themeSetting === "auto") {
        let systemDark =
          window.matchMedia &&
          window.matchMedia("(prefers-color-scheme: dark)").matches;

        this.$vuetify.theme.dark = systemDark;

        window
          .matchMedia("(prefers-color-scheme: dark)")
          .addEventListener("change", (e) => {
            this.$vuetify.theme.dark = e.matches;
          });
      } else {
        let isDark = themeSetting === "dark";
        this.$vuetify.theme.dark = isDark;
      }

      this.setDocumentThemeColor(this.$vuetify.theme.dark);
    },
    initOfflineData() {
      return new Promise((resolve, reject) => {
        let openRequest = indexedDB.open("nguondat_db", 1);

        openRequest.onupgradeneeded = async (event) => {
          console.log("IndexedDB Upgraded");

          const db = event.target.result;

          db.createObjectStore("provinces", {
            keyPath: "id",
          });

          db.createObjectStore("districts", {
            keyPath: "id",
          }).createIndex("parentIdx", "province_id");

          db.createObjectStore("wards", {
            keyPath: "id",
          }).createIndex("parentIdx", "district_id");

          let provinces = await this.fetchProvinces();
          const provinceStore = db
            .transaction("provinces", "readwrite")
            .objectStore("provinces");

          for (const province of provinces) {
            provinceStore.add(province);
          }

          let districts = await this.fetchDistricts();
          const districtStore = db
            .transaction("districts", "readwrite")
            .objectStore("districts");

          for (const district of districts) {
            districtStore.add(district);
          }

          let wards = await this.fetchWards();
          const wardStore = db
            .transaction("wards", "readwrite")
            .objectStore("wards");

          for (const ward of wards) {
            wardStore.add(ward);
          }

          resolve(true);
        };

        openRequest.onsuccess = (event) => {
          const db = event.target.result;
          this.setDB(db);
          resolve(true);
        };

        openRequest.onerror = function (err) {
          console.log("Can't open the IndexedDB!");
          reject(err);
        };
      });
    },
  },

  head: {
    title: {
      inner: "Trang chủ",
      complement: "NGUỒN ĐẤT",
    },
  },
};
</script>
