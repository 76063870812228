const filter = (v) => {
  if (v >= 10000) {
    const t = v / 10000;
    return `${t}ha`;
  }

  return `${v}m²`;
};

export default filter;
