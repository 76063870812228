<template>
  <v-dialog
    :value="paymentDialog"
    fullscreen
    scrollable
    transition="dialog-bottom-transition"
  >
    <v-card tile>
      <v-card-title class="pa-0">
        <v-toolbar elevation="0">
          <v-btn icon @click="close">
            <v-icon>fa fa-times</v-icon>
          </v-btn>
          <v-toolbar-title class="ps-0">Gia hạn dịch vụ</v-toolbar-title>
        </v-toolbar>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-4">
        <v-stepper v-model="step" flat tile>
          <v-stepper-items>
            <v-stepper-content step="1" class="pa-0">
              <payment-packs ref="packs" @submit="onPackSubmit" />
            </v-stepper-content>

            <v-stepper-content step="2" class="pa-0">
              <v-btn text class="mb-3" @click="step = 1">
                <v-icon left>fa fa-chevron-left</v-icon>
                <span>Quay lại</span>
              </v-btn>

              <banking-cards :pack="pack" />

              <div class="text-center" @click="submit">
                <v-btn :loading="loading" class="mt-5" color="success">
                  <span>Xác nhận</span>
                </v-btn>
              </div>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import BankingCards from "./BankingCards.vue";
import PaymentPacks from "./PaymentPacks.vue";
export default {
  name: "PaymentDialog",
  components: { PaymentPacks, BankingCards },
  props: {},
  data: () => ({
    step: 1,
    pack: null,
    payMethod: null,
    loading: false,
  }),
  computed: {
    ...mapState(["paymentDialog"]),
    ...mapState("auth", ["user"]),
  },
  watch: {
    paymentDialog(isOpen) {
      if (isOpen && this.$refs.packs) {
        this.$refs.packs.fetch();
      }
    },
  },
  methods: {
    ...mapMutations(["setPaymentDialog"]),
    ...mapActions(["showSuccess"]),
    close() {
      this.setPaymentDialog(false);
    },
    onPackSubmit(data) {
      this.pack = data.pack;
      this.payMethod = data.payMethod;

      if (this.payMethod == "banking") {
        this.step = 2;
      } else {
        this.close();
        const payWindow = window.open(
          `${process.env["VUE_APP_RESOURCE_URL"]}/vnpay/create?user_id=${this.user.id}&pack_code=${this.pack.code}`,
          "_blank"
        );
        payWindow.onclose = () => {
          console.log("ok");
        };
      }
    },
    async submit() {
      this.loading = true;
      await this.$http
        .post("/api/payments", {
          pack_code: this.pack.code,
          method: this.payMethod,
        })
        .finally(() => {
          this.loading = false;
        });

      this.pack = null;
      this.payMethod = null;
      this.step = 1;

      this.close();
      this.showSuccess("Đã gửi yêu cầu");
    },
  },
};
</script>

<style scope>
.v-stepper {
  background: none !important;
}
</style>
