<template>
  <div>
    <v-card v-if="promotion" rounded="lg" flat color="success">
      <v-card-text class="text-center white--text">
        <div class="mb-3">{{ promotion.description }}</div>
        <div class="text-uppercase subtitle-2 black--text mb-2">
          Kết thúc sau
        </div>
        <countdown :value="promotion.expired_at" @expired="onExpired" />
      </v-card-text>
    </v-card>

    <v-list>
      <v-radio-group v-model="selected" class="mt-0">
        <v-card
          v-for="(pack, i) of packs"
          :key="`payment-pack-list-item-${i}`"
          :class="{ 'mb-3': i < packs.length - 1 }"
          rounded="lg"
          flat
          :color="
            selected == i
              ? 'primary'
              : $vuetify.theme.isDark
              ? 'grey darken-3'
              : 'grey lighten-3'
          "
          :dark="selected == i"
          @click="selected = i"
        >
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ pack.duration }} tháng</v-list-item-title>
              <v-list-item-subtitle
                v-if="pack.discount_percent > 0"
                class="text-decoration-line-through"
              >
                {{ pack.amount.toLocaleString("vi-VN") }}đ
              </v-list-item-subtitle>
              <v-list-item-subtitle v-else-if="promotion">
                Không áp dụng khuyến mãi
              </v-list-item-subtitle>
              <v-list-item-subtitle
                class="text-h6 font-weight-bold"
                :class="{ 'white--text': selected == i }"
                >{{
                  Math.floor(
                    pack.amount - pack.amount * (pack.discount_percent / 100)
                  ).toLocaleString("vi-VN")
                }}đ</v-list-item-subtitle
              >
            </v-list-item-content>
            <div
              v-if="pack.discount_percent > 0"
              :class="{ 'green--text': selected != i }"
            >
              -{{ pack.discount_percent }}%
            </div>
            <v-list-item-action>
              <v-radio :value="i" color="white" :ripple="false"></v-radio>
            </v-list-item-action>
          </v-list-item>
        </v-card>
      </v-radio-group>
    </v-list>

    <v-radio-group v-model="payMethod" class="mt-0">
      <v-radio value="banking" label="Chuyển khoản ngân hàng"></v-radio>
      <v-radio value="vnpay" label="VNPAY (Đang bảo trì)" disabled></v-radio>
    </v-radio-group>

    <v-btn
      block
      large
      depressed
      class="rounded-lg mb-10"
      color="success"
      @click="submit"
      >Thanh toán</v-btn
    >
  </div>
</template>

<script>
import Countdown from "../../components/Countdown.vue";

export default {
  name: "PaymentPacks",
  components: { Countdown },
  props: {},
  data: () => ({
    packs: [],
    selected: 0,
    payMethod: "banking",
    promotion: null,
    discount: {
      message:
        "Chương trình giảm giá lên đến 50% trong tuần đầu tiên ra mắt dành cho khách hàng đăng ký mới từ 3 tháng trở lên",
      expried_at: new Date(2022, 11, 28, 23, 59, 59),
    },
  }),
  created() {
    this.fetch();
  },
  methods: {
    async fetch() {
      const { data } = await this.$http.get("/api/promotions");

      if (data.length > 0) {
        this.promotion = data[0];
        this.packs = data[0].payment_packs;
      } else {
        this.promotion = null;
        const { data } = await this.$http.get("/api/payment-packs");
        this.packs = data;
      }
    },
    submit() {
      this.$emit("submit", {
        pack: this.packs[this.selected],
        payMethod: this.payMethod,
      });
    },
    async onExpired() {
      this.promotion = null;
      const { data } = await this.$http.get("/api/payment-packs");
      this.packs = data;
    },
  },
};
</script>

<style></style>
