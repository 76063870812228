import Vue from "vue";
import CKEditor from "@ckeditor/ckeditor5-vue2";

Vue.use(CKEditor);

const config = {
  toolbar: {
    items: [
      "undo",
      "redo",
      "|",
      "heading",
      "|",
      "bold",
      "italic",
      "underline",
      "|",
      "alignment",
      "fontColor",
      "highlight",
      "|",
      "bulletedList",
      "numberedList",
      "|",
      "outdent",
      "indent",
      "|",
      "link",
      "horizontalLine",
      "imageUpload",
      "imageInsert",
      "mediaEmbed",
      "blockQuote",
      "insertTable",
      "|",
      "removeFormat"
    ],
  },
  language: "vi",
  image: {
    toolbar: [
      "imageTextAlternative",
      "imageStyle:inline",
      "imageStyle:block",
      "imageStyle:side",
    ],
  },
  table: {
    contentToolbar: [
      "tableColumn",
      "tableRow",
      "mergeTableCells",
      "tableCellProperties",
      "tableProperties",
    ],
  },
  simpleUpload: {
    // The URL that the images are uploaded to.
    uploadUrl: process.env.VUE_APP_RESOURCE_URL + "/api/ck",

    // Headers sent along with the XMLHttpRequest to the upload server.
    headers: {
      accept: "application/json",
    },
  },
  licenseKey: "",
};

export default config;
