import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import { vi } from "vuetify/lib/locale";
import colors from "vuetify/lib/util/colors";
// import icons from "vuetify/lib/services/icons/presets/fa"
import "../assets/FontAwesome.Pro.6.2.1/css/fontawesome.css"
import "../assets/FontAwesome.Pro.6.2.1/css/light.css"
import "../assets/FontAwesome.Pro.6.2.1/css/regular.css"
import "../assets/FontAwesome.Pro.6.2.1/css/solid.css"
import "../assets/FontAwesome.Pro.6.2.1/css/brands.css"
import "../assets/FontAwesome.Pro.6.2.1/css/duotone.css"

Vue.use(Vuetify);

vi.dataTable.itemsPerPageText = "Hiển thị";

const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: colors.indigo,
        secondary: colors.blue,
        accent: colors.indigo.lighten2,
      },
      dark: {
        primary: colors.indigo,
        secondary: colors.blue,
        accent: colors.indigo.lighten2,
      },
    },
  },
  icons: {
    iconfont: "fa", // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg',
    values: {
      prev: 'far fa-chevron-left',
      next: 'far fa-chevron-right',
    },
  },
  lang: {
    locales: { vi },
    current: "vi",
  },
});

export default vuetify;
