import { round } from 'lodash';

const filter = (v, short = false) => {
  if (v >= 1000000000) {
    const t = round(v / 1000000000, short ? 0 : 2);
    return `${t} tỷ`
  } else if (v >= 1000000) {
    const t = round(v / 1000000, short ? 0 : 2);
    return `${t}${short ? 'tr' : ' triệu'}`
  } else {
    const t = round(v/1000, short ? 0 : 2);
    return `${t}k`
  }
}

export default filter