import Vue from "vue";
import Vuex from "vuex";
import authModule from './auth';
import optionsModule from './options';
import manageModule from './manage';
import zoneModule from './zones';
import mapsModule from './maps';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    viewport: {
      height: visualViewport.height,
      width: visualViewport.width,
      offsetLeft: visualViewport.offsetLeft,
      offsetTop: visualViewport.offsetTop,
      pageLeft: visualViewport.pageLeft,
      pageTop: visualViewport.pageTop,
    },
    view: 'home',
    snackbar: {
      isOpen: false,
      color: "default",
      message: "",
    },
    confirmDialog: {
      isOpen: false,
      title: "",
      message: "",
    },
    rules: {
      required: (value) => !!value || "Bắt buộc (*)",
      price: (value) => {
        if (value === null) return true;

        let price = value.replace(/\D/g, "");

        if (price) {
          price = parseInt(price);

          if (price <= 0) {
            return "Bắt buộc (*)";
          }

          return true;
        }

        return "Bắt buộc (*)";
      },
    },
    online: true,
    db: null,
    paymentDialog: false
  },
  mutations: {
    setOnline(state, data) {
      state.online = data;
    },
    setViewport(state, data) {
      state.viewport = data
    },
    setView(state, v) {
      state.view = v;
    },
    setDB(state, v) {
      state.db = v;
    },
    setPaymentDialog(state, v){
      state.paymentDialog = v;
    }
  },
  actions: {
    showSuccess({ state }, message) {
      let delay = 0;

      if (state.snackbar.isOpen) {
        state.snackbar.isOpen = false;
        delay = 100;
      }

      setTimeout(() => {
        state.snackbar = {
          isOpen: true,
          color: "success",
          message,
        };
      }, delay);
    },
    showInfo({ state }, message) {
      let delay = 0;

      if (state.snackbar.isOpen) {
        state.snackbar.isOpen = false;
        delay = 100;
      }

      setTimeout(() => {
        state.snackbar = {
          isOpen: true,
          color: "info",
          message,
        };
      }, delay);
    },
    showError({ state }, message) {
      let delay = 0;

      if (state.snackbar.isOpen) {
        state.snackbar.isOpen = false;
        delay = 100;
      }

      setTimeout(() => {
        state.snackbar = {
          isOpen: true,
          color: "error",
          message,
        };
      }, delay);
    },
    closeSnackbar({ state }) {
      state.snackbar = {
        isOpen: false,
        color: "default",
        message: ""
      }
    },
    confirm({ state }, { title, message }) {
      state.confirmDialog = {
        isOpen: true,
        title,
        message,
      };
    }
  },
  modules: {
    auth: authModule,
    options: optionsModule,
    manage: manageModule,
    zones: zoneModule,
    maps: mapsModule
  },
});
