<template>
  <div class="d-inline-flex justify-space-between" style="width: 180px">
    <div v-if="countdown.days" class="d-flex flex-column">
      <div class="text-h5 font-weight-bold">{{ countdown.days }}</div>
      <div>NGÀY</div>
    </div>

    <div class="d-flex flex-column">
      <div class="text-h5 font-weight-bold">{{ countdown.hours }}</div>
      <div>GIỜ</div>
    </div>

    <div class="d-flex flex-column">
      <div class="text-h5 font-weight-bold">{{ countdown.minutes }}</div>
      <div>PHÚT</div>
    </div>

    <div class="d-flex flex-column">
      <div class="text-h5 font-weight-bold">{{ countdown.seconds }}</div>
      <div>GIÂY</div>
    </div>
  </div>
</template>

<script>
const second = 1000,
  minute = second * 60,
  hour = minute * 60,
  day = hour * 24;

export default {
  name: "Countdown",

  props: {
    value: String,
  },

  data: () => ({
    countdown: {
      days: "-",
      hours: "-",
      minutes: "-",
      seconds: "-",
    },
    interval: null,
    future: null
  }),

  watch: {
    value() {
      this.run();
    },
  },

  created() {
    this.future = new Date(this.value);
    this.run();
  },

  destroyed(){
    clearInterval(this.interval);
  },

  methods: {
    calculate() {
      let now = new Date();

      let distance = this.future - now;

      if (distance <= 0) {
        this.stop();
        return;
      }

      let days = Math.floor(distance / day);
      let hours = Math.floor((distance % day) / hour);
      let minutes = Math.floor((distance % hour) / minute);
      let seconds = Math.floor((distance % minute) / second);
      this.countdown = { days, hours, minutes, seconds };
    },
    run() {
      if (!this.value) return;
      this.calculate();

      this.interval = setInterval(() => {
        this.calculate();
      }, 1000);
    },
    stop() {
      clearInterval(this.interval);
      this.$emit("expired");
    },
  },
};
</script>

<style></style>
