const filter = (v) => {
  if(!v) return 'Không'

  if (v >= 1000) {
    const t = v / 1000
    return `${t}km`
  }

  return `${v}m`
}

export default filter